import React, { Component } from "react";

class ContactEmail extends Component {
  render() {
    if (this.props.data) {
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }

    return (
      <section id="contact">
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Get In Touch</span>
            </h1>
          </div>
          <div className="ten columns">
            <p className="lead">{message}</p>
            <p className="lead">
              Email:
              <span>
                <a href="mailto:faisal.cx.web@gmail.com"> {email}</a>
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
export default ContactEmail;
