import React, { Component } from 'react';

class TestimonialsNew extends Component {
  render() {
    return (
      <section id='testimonials'>
        <div className='text-container'>
          <div className='row'>
            <div className='two columns header-col'>
              <h1>
                <span>Testimonials</span>
              </h1>
            </div>
            <div className='ten columns flex-container'>
              <div className='flexslider'>
                <ul className='slides'>
                  <li>
                    <blockquote>
                      <p>
                        Faisal is a thoughtful and talented architect. He asks
                        the right questions and thinks about a problem from the
                        big picture - always looking ahead to how we might apply
                        a solution to a larger goal, while staying agile in
                        mindset and approach. Faisal is also just a genuinely
                        nice person, and a pleasure to work with. I highly
                        recommend Faisal, and hope to work with him again in the
                        future.
                      </p>
                      <cite>Megan Schwarz, UX Design Lead - Aon</cite>
                    </blockquote>
                  </li>
                  <li>
                    <blockquote>
                      <p>
                        Faisal was amazing to work with on the AlinIQ BIS
                        project at Abbott Diagnostics. He was my go-to person
                        for creative solutions for complex issues regarding AWS,
                        coding, new development, etc. One of his strengths was
                        to be able to be adaptable and agile in his environment,
                        as taking on responsibilities as needed such as scrum
                        master, mini-project manager, AWS consultant, lead
                        developer, and coach. I recommend Faisal since he was
                        such an asset to my team and me.
                      </p>
                      <cite>
                        Daniel Johnson, Sr. Global Informatics Product Manager -
                        Abbott
                      </cite>
                    </blockquote>
                  </li>
                  <li>
                    <blockquote>
                      <p>
                        Faisal is a motivated, broad-minded, and
                        forward-thinking professional who has a lot of knowledge
                        in his field. He is wonderful to work with and has
                        exceptional expertise in software and technical aspects
                        of wireless communications. Faisal is a dedicated,
                        self-motivated, and very capable technical professional.
                        He is able to adapt to changing work environments, and a
                        very good team player.
                      </p>
                      <cite>
                        Rommel Gandeza, IMS/BIS Business Analyst - Abbott
                      </cite>
                    </blockquote>
                  </li>
                  <li>
                    <blockquote>
                      <p>
                        It was my pleasure working with Faisal at Abbott. Faisal
                        is thoughtful in software and hardware integration which
                        is required in the RFID-based AlinIQ IMS (Inventory
                        Management System). He was one of the key contributors
                        who ultimately led the product to a huge business
                        growth. I wish Faisal success in future endeavor.
                      </p>
                      <cite>
                        George Shi, Healthcare IT Product Management - Abbott
                      </cite>
                    </blockquote>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default TestimonialsNew;
